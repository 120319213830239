html {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:5px;
  padding-top:0px;
  color: snow;
  background-color: #121211;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

body {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

footer {
  margin-top: 20px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

.bulletpoints {
  display:flex;
  flex-direction: column;
  align-items: center;
text-align: left;
  gap:1rem;
  max-width: 800px;
}

@media only screen and (max-width: 600px) {
  header {
    flex-direction: column;
  }
}